import { USER_FEEDBACK_ROUTE, USER_MANAGEMENT_ROUTE } from '@/router/routeNames'

export const USER_MENU_OPTIONS = Object.freeze({
  userManagement: {
    title: USER_MANAGEMENT_ROUTE.title,
    path: USER_MANAGEMENT_ROUTE.path,
    icon: 'settings-multi'
  },
  userFeedback: {
    title: USER_FEEDBACK_ROUTE.title,
    path: USER_FEEDBACK_ROUTE.path,
    icon: 'user-comment-outline'
  }
})
