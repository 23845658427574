import { reactive } from 'vue'
import { getNotificationState } from '@/api'
import { NOTIFICATION_TYPES } from '@/constants/notifications'

const notificationsState = reactive({
  newNotificationAvailable: false,
  newModalNotificationAvailable: false,
  lastSeenTimestamp: '',
  isLoading: false
})

export const useNotificationsState = () => {
  const setNotificationState = ({ type, availableNotifications, lastSeenTimestamp }) => {
    if (type === NOTIFICATION_TYPES.whatsNew) {
      notificationsState.newNotificationAvailable = availableNotifications
    }
    if (type === NOTIFICATION_TYPES.whatsNewModal) {
      notificationsState.newModalNotificationAvailable = availableNotifications
    }
    notificationsState.lastSeenTimestamp = lastSeenTimestamp
  }

  const fetchNotificationsState = async ({ type, seen }) => {
    notificationsState.isLoading = true
    try {
      const { showNotification, timestamp } = await getNotificationState({ type, seen })
      setNotificationState({
        type,
        availableNotifications: showNotification,
        lastSeenTimestamp: timestamp
      })
    }
    finally {
      notificationsState.isLoading = false
    }
  }

  return {
    notificationsState,
    fetchNotificationsState,
    setNotificationState
  }
}
