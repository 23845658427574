import { reactive } from 'vue'
import { getWhatsNewData, getModalWhatsNewData } from '@/api'
import { PRODUCT_AREA_FILTER } from '@/constants/whatsNew'

const whatsNew = reactive({
  whatsNewData: [],
  whatsModalNewData: {},
  filteredNewData: {},
  whatsNewIsLoading: false
})

export const useWhatsNew = () => {
  const setFilteredWhatsNewData = (name = '') => {
    if (PRODUCT_AREA_FILTER[0].name === name) {
      whatsNew.filteredNewData = whatsNew.whatsNewData
    }
    else {
      whatsNew.filteredNewData = whatsNew.whatsNewData.map(entry => {
        const parsedData = JSON.parse(entry.data)
        const filteredData = parsedData.filter(item => (Array.isArray(item.product_name)
          ? item.product_name.includes(name)
          : item.product_name === name))

        return {
          ...entry,
          data: JSON.stringify(filteredData)
        }
      }).filter(entry => JSON.parse(entry.data).length > 0)
    }
  }

  const fetchWhatsNewData = async () => {
    whatsNew.whatsNewIsLoading = true
    try {
      const data = await getWhatsNewData()

      whatsNew.whatsNewData = data.sort((a, b) => {
        const timestampA = new Date(a.version_timestamp)
        const timestampB = new Date(b.version_timestamp)
        return timestampB - timestampA
      })
      setFilteredWhatsNewData(PRODUCT_AREA_FILTER[0].name)
    }
    finally {
      whatsNew.whatsNewIsLoading = false
    }
  }

  const fetchModalWhatsNewData = async timestampStart => {
    whatsNew.whatsNewIsLoading = true
    try {
      const data = await getModalWhatsNewData({ timestampStart })
      whatsNew.whatsModalNewData = data.flatMap(group => JSON.parse(group.data)
        .map(item => ({
          title: item.content.title,
          topics: item.content.topics,
          section: item.section,
          type: item.type
        })))
        .reduce((acc, item) => {
          const existingEntry = acc.find(entry => entry.title === item.title)
          if (existingEntry) {
            existingEntry.topics = existingEntry.topics.concat(item.topics)
            existingEntry.section = item.section
            existingEntry.type = item.type
          }
          else {
            acc.push(item)
          }
          return acc
        }, [])
    }
    finally {
      whatsNew.whatsNewIsLoading = false
    }
  }

  return {
    whatsNew,
    fetchWhatsNewData,
    setFilteredWhatsNewData,
    fetchModalWhatsNewData
  }
}
