import cloneDeep from 'lodash.clonedeep'

export const PRODUCT_AREA = [{ key: 'Variant management', value: false },
  { key: 'Rule validations', value: false },
  { key: 'PAC', value: false },
  { key: 'Overall performance', value: false }]

export const PRODUCT_AREA_CHECK_OBJ = {
  features: cloneDeep(PRODUCT_AREA),
  bugfixes: cloneDeep(PRODUCT_AREA)
}

export const PRODUCT_AREA_FILTER = Object.freeze([
  { key: 'DISPLAY_ALL', name: 'Display all' },
  { key: 'VARIANT_MANAGEMENT', name: 'Variant management' },
  { key: 'RULE_VALIDATIONS', name: 'Rule validations' },
  { key: 'PAC', name: 'PAC' },
  { key: 'OVERALL_PERFORMANCE', name: 'Overall performance' }
])

export const EMPTY_CREATE_WHATS_NEW_TEMPLATE = {
  features: { type: null, content: '' },
  bugfixes: { type: null, content: '' }
}

export const TEXT_DATA = Object.freeze({
  product: {
    label: 'Product area',
    description: 'Select a feature'
  },
  features: {
    section: 'New features section',
    label: 'New features',
    description: '- Features description...',
    hint: 'To enter as bullet point, add the symbol - followed by space.'
  },
  bugfixes: {
    section: 'Bug fix section',
    label: 'Bug fixes',
    description: '- Bug fixes description...',
    hint: 'To enter as bullet point, add the symbol - followed by space.'
  }
})

export const BODY_TEMPLATE = Object.freeze([
  {
    section: 'Now you can',
    type: 'New',
    product_name: '',
    content: {
      title: "We've added some new features",
      topics: []
    }
  },
  {
    section: 'Bug fixes',
    type: 'Fixed',
    product_name: '',
    content: {
      title: 'And we also fixed some bugs',
      topics: []
    }
  }
])

export const INFO_TAB_NAMES = Object.freeze({
  USER: 'user',
  ADMIN: 'admin'
})
