<template>
  <div class="create-whats-new__wrapper">
    <mi-form @submit="submitWhatsNew">
      <div class="create-whats-new__title">
        <span>{{ textData.features.section }}</span>
      </div>
      <div class="create-whats-new__form row">
        <mi-select
          v-model="createData.features.type"
          :display-value="productDisplayName.features"
          :options="productAreaData.features"
          class="q-mb-sm mi-select-margin"
          options-selected-class="text-accent"
          outlined
        >
          <!-- Option slot -->
          <template #option="{ opt, itemProps }">
            <mi-list-item :key="opt.key" v-bind="itemProps">
              <!-- Label -->
              <mi-list-item-section @click="updateCheckbox(opt, 'features')">
                <mi-list-item-label>
                  <mi-checkbox
                    :id="`features-${ opt.key }`"
                    v-model="opt.value"
                    :label="opt.key"
                    @click="updateDisplayName('features')"
                  ></mi-checkbox>
                </mi-list-item-label>
              </mi-list-item-section>
            </mi-list-item>
          </template>
        </mi-select>

        <mi-text-field
          v-model="createData.features.content"
          class="q-mb-sm"
          :label="textData.features.label"
          :placeholder="textData.features.description"
          :hint="textData.features.hint"
          outlined
        ></mi-text-field>
      </div>
      <div class="create-whats-new__divider"></div>
      <div class="create-whats-new__title">
        <span>{{ textData.bugfixes.section }}</span>
      </div>
      <div class="create-whats-new__form row">
        <mi-select
          v-model="createData.bugfixes.type"
          :display-value="productDisplayName.bugfixes"
          :options="productAreaData.bugfixes"
          class="q-mb-sm mi-select-margin"
          options-selected-class="text-accent"
          outlined
        >
          <!-- Option slot -->
          <template #option="{ opt, itemProps }">
            <mi-list-item :key="opt.key" v-bind="itemProps">
              <!-- Label -->
              <mi-list-item-section @click="updateCheckbox(opt, 'bugfixes')">
                <mi-list-item-label>
                  <mi-checkbox
                    :id="`features-${ opt.key }`"
                    v-model="opt.value"
                    :label="opt.key"
                    @click="updateDisplayName('bugfixes')"
                  ></mi-checkbox>
                </mi-list-item-label>
              </mi-list-item-section>
            </mi-list-item>
          </template>
        </mi-select>

        <mi-text-field
          v-model="createData.bugfixes.content"
          class="q-mb-sm"
          :label="textData.bugfixes.label"
          :placeholder="textData.bugfixes.description"
          :hint="textData.bugfixes.hint"
          outlined
        ></mi-text-field>
      </div>

      <div class="create-whats-new__submit">
        <div class="create-whats-new__submit--submit">
          <mi-btn type="submit" color="accent"> Submit Update </mi-btn>
        </div>
        <div class="create-whats-new__submit--checkbox">
          <mi-checkbox v-model="highlightFlag" size="lg" label=" Submit also as a modal"></mi-checkbox>
        </div>
      </div>
    </mi-form>
  </div>
</template>

<script>
  import {
    BODY_TEMPLATE,
    EMPTY_CREATE_WHATS_NEW_TEMPLATE,
    PRODUCT_AREA_CHECK_OBJ,
    TEXT_DATA
  } from '@/constants/whatsNew'
  import notify from '@/utils/notify'
  import cloneDeep from 'lodash.clonedeep'
  import { addWhatsNewData } from '@/api'
  import { onBeforeMount, reactive, ref } from 'vue'
  import { useWhatsNew } from './composables/useWhatsNew'

  export default {
    name: 'CreateWhatsNew',
    setup() {
      const createData = reactive(cloneDeep(EMPTY_CREATE_WHATS_NEW_TEMPLATE))
      const productDisplayName = reactive({
        features: TEXT_DATA.product.description,
        bugfixes: TEXT_DATA.product.description
      })
      const textData = TEXT_DATA
      const productAreaData = reactive(PRODUCT_AREA_CHECK_OBJ)
      const highlightFlag = ref(false)

      const { fetchWhatsNewData } = useWhatsNew()

      const getProductModels = type => productAreaData[type]
        .filter(item => item.value)
        .map(item => item.key)

      const updateDisplayName = type => {
        const listOfProducts = getProductModels(type)
        const { description } = textData.product

        productDisplayName[type] = listOfProducts.length
          ? `${ listOfProducts.join('; ').slice(0, 30) }${
            listOfProducts.length > 1 ? '...' : ''
          }`
          : description
      }

      const updateCheckbox = (opt, type) => {
        opt.value = !opt.value
        updateDisplayName(type)
      }

      const buildBody = () => {
        const { features, bugfixes } = createData
        const body = cloneDeep(BODY_TEMPLATE)
        const updateBody = (data, index) => {
          const productModel = index === 0 ? 'features' : 'bugfixes'
          data.type = getProductModels(productModel)
          if (data?.type && data?.content) {
            body[index].product_name = data?.type
            body[index].content.topics = data.content.split('-').filter(Boolean)
          }
        }

        updateBody(features, 0)
        updateBody(bugfixes, 1)

        return body
      }

      const cleanUpCheckbox = type => {
        productAreaData[type] = productAreaData[type].map(product => ({
          ...product,
          value: false
        }))
      }

      const setInitialForm = () => {
        cleanUpCheckbox('features')
        cleanUpCheckbox('bugfixes')
      }

      const notification = () => {
        notify({
          title: 'COMPLETED',
          content: 'Whats new entry was successfully created.',
          type: 'dark',
          progress: false
        })
      }

      const submitWhatsNew = async () => {
        const body = buildBody()

        const isFirstBodyEmpty = !body[0]?.content.topics.length
        const isSecondBodyEmpty = !body[1]?.content.topics.length

        if (isFirstBodyEmpty && isSecondBodyEmpty) {
          notify({
            title: 'ERROR',
            content: 'Please fill in at least one field.',
            type: 'negative',
            progress: false
          })
          return
        }

        try {
          await addWhatsNewData(body, { highlightFlag: highlightFlag.value })
        }
        finally {
          notification()
          await fetchWhatsNewData()
        }
      }

      onBeforeMount(() => {
        setInitialForm()
      })

      return {
        updateCheckbox,
        submitWhatsNew,
        updateDisplayName,
        highlightFlag,
        createData,
        productDisplayName,
        textData,
        productAreaData,
        buildBody,
        getProductModels
      }
    }

  }
</script>

<style lang="scss" scoped>

  .create-whats-new {
    &__wrapper {
      margin-left: 5%;
      color: $mi-anthracite-800;
      font-family: $mi-typography-font-family-condensed;
    }

    &__title {
      width: 176px;
      height: 27px;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      text-transform: uppercase;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    &__form {
      margin-top: 32px;

      .q-field:nth-child(1) {
        width: 45%;
      }

      .q-field:nth-child(2) {
        width: 85%;
      }

      .mi-select-margin {
        margin-bottom: 32px;
      }
    }

    &__submit {
      margin: 32px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      &--checkbox {
        margin-left: 24px;
        font-family: $mi-typography-font-family;
      }
    }

    &__divider {
      height: 2px;
      width: 85%;
      margin: 32px 0;
      background-color: $mi-anthracite-50;
    }
  }
</style>
